<template>
    <v-virtual-scroll
        class="cols-12"
        :bench="benched"
        :items="files"
        :height="scrollerHeight"
        item-height="100"
        ref="virtualScroller"
    >
        <template v-slot:default="{ item }">
            <v-list-item
                link
                three-line
                @click.stop="selected(item)"
            >
                <div class="d-flex align-center">
                    <v-list-item-action>
                        <v-btn large icon @click.stop="playContent(item)" class="custom-btn">
                            <v-icon>mdi-play</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </div>
                <v-list-item-content>
                    <v-list-item-title
                        v-text="`${item.NomeFile}`"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                        <v-icon left small> mdi-calendar-start</v-icon>
                        <span class="schedule-start" :class="subtitleClass"
                            >{{ scheduleText(item).start }}
                        </span>
                        <v-spacer></v-spacer>
                        <v-icon left small v-if="startEndDiff(item) < 300">
                            mdi-calendar-end
                        </v-icon>
                        <span v-if="startEndDiff(item) < 300" class="schedule-end" :class="subtitleClass"
                            >{{ scheduleText(item).end }}
                        </span>
                        <DaysOfWeek :days="item.GGSettimanali" v-else></DaysOfWeek>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip color="gray">
                        <v-avatar left class="grey lighten-1">
                            {{ item.Stores.length }}
                        </v-avatar>
                        {{
                            item.Stores.length === 1
                                ? "Negozio"
                                : "Negozi"
                        }}
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
        </template>
    </v-virtual-scroll>
</template>

<script>
export const DaysOfWeek = () => import("../core/DaysOfWeek");

import { mapMutations, mapState } from "vuex";

import * as appActions from "../../store/actions/app";

export default {
    props: {
        files: {
            type: Array,
            required: true
        },
        scrollerHeight: {
            type: Number,
            required: true
        }
    },

    data: () => ({
        benched: 4
    }),

    components: {
        DaysOfWeek
    },

    computed: {
        subtitleClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "text-caption";
                case "sm":
                    return "text-caption";
                case "md":
                    return "text-caption";
                case "lg":
                    return "text-caption";
                case "xl":
                    return "text-caption";
            }
            return "";
        },
    },

    methods: {

        playContent(item) {
            this.$emit('playcontent', item)
        },

        startEndDiff(item) {
            return (new Date(item.Al) - new Date(item.Dal))/(1000*60*60*24)
        },

        // promoState(promo) {
        //     const disabled = compareDate(promo.FineValidita) === 1;
        //     const toStart = compareDate(promo.InizioValidita) === -1;
        //     const deleted = promo.Action === 1;
        //     const suspended = promo.Sospeso === true;

        //     if (suspended || disabled && !deleted) {
        //         return "warning";
        //     }
        //     else if (deleted) {
        //         return "error";
        //     }  else if (toStart) {
        //         return "info";
        //     }
        //     return "success";
        // },

        // promoMessage(promo) {
        //     const disabled = compareDate(promo.FineValidita) === 1;
        //     const toStart = compareDate(promo.InizioValidita) === -1;
        //     const deleted = promo.Action === 1;
        //     const suspended = promo.Sospeso === true;

        //     //console.log(new Date(promo.InizioValidita).toLocaleDateString('it-IT'), dt.toLocaleDateString('it-IT'))

        //     if (suspended) {
        //         return "Sospesa";
        //     }
        //     else if (deleted) {
        //         return "Cancellata";
        //     } else if (disabled) {
        //         return "Scaduta";
        //     } else if (toStart) {
        //         return "Non Pubblicata";
        //     }
        //     return "Pubblicata";
        // },

        scheduleText(promo) {
            const from = new Date(promo.Dal);
            const to = new Date(promo.Al);
            const validità = {
                start: from.toLocaleString("it-it", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                }),
                end: to.toLocaleString("it-it", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                })
            };

            return validità;
        },

        selected(id) {
            this.$emit("selected", id);
        },
    },

    beforeMount() {
        /* console.log(this.scrollerHeight) */
        // this.resetStore();
    },

    mounted() {
        this.$nextTick(() => {
        });
    }
};
</script>

<style scoped>
.schedule-start,
.schedule-end {
    text-transform: capitalize;
}

.v-virtual-scroll {
    padding-bottom: 128px;
}

.custom-btn::before {
    color: blue;
}

.custom-btn:hover {
    color: grey;
}
</style>
